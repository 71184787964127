import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <filter id="shadow-eboti-6" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M121.58 135.67v14.17c0 11.19-8 20.27-17.82 20.28-9.85 0-17.86-9-17.87-20.23v-14.16c0-9.78 7-17.71 15.56-17.72H106c8.57-.01 15.56 7.89 15.58 17.66z"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-2{fill:#199b69}.prefix__cls-5{fill:none;stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.97px}'
          }
        </style>
      </defs>
      <path
        className="prefix__cls-2"
        d="M128.66 47.71a20.84 20.84 0 01-20.84 20.84 20.84 20.84 0 0120.84-20.84z"
        transform="rotate(9.39 118.226 58.14)"
      />
      <path
        className="prefix__cls-1"
        d="M80.91 32.32A32.67 32.67 0 01113.58 65a32.67 32.67 0 01-32.67-32.68z"
        transform="rotate(-170.61 97.246 48.658)"
      />
      <g
        filter="url(#shadow-eboti-6)"
        id="prefix__Boti_GRP"
        data-name="Boti GRP"
      >
        <path
          className="prefix__cls-1"
          d="M121.58 135.67v14.17c0 11.19-8 20.27-17.82 20.28-9.85 0-17.86-9-17.87-20.23v-14.16c0-9.78 7-17.71 15.56-17.72H106c8.57-.01 15.56 7.89 15.58 17.66z"
        />
        <g clipPath="url(#prefix__clip-path)">
          <path
            className="prefix__cls-2"
            d="M121.27 130.63l1.31 11A75.83 75.83 0 0184.33 146L83 135.18c-1.25-10.47 5.22-19.85 14.44-20.94l4.84-.58c9.26-1.1 17.72 6.5 18.99 16.97z"
          />
        </g>
        <path
          id="prefix__HAND_R"
          data-name="HAND R"
          className="prefix__cls-1"
          d="M154.6 151.06a24.57 24.57 0 00-18.82-6.74 24.72 24.72 0 0026.48 25.2 24.57 24.57 0 00-7.66-18.46z"
        />
        <path
          className="prefix__cls-1"
          d="M169 114.87l-5-8.87A89.65 89.65 0 0042.17 71.11L33.3 76a5.81 5.81 0 00-2.3 7.91l5 8.87a89.08 89.08 0 0053.7 42.68 91.68 91.68 0 0017.27 3.19 89 89 0 0050.89-11l8.76-4.86a6 6 0 002.77-3.2 5.77 5.77 0 00-.39-4.72z"
        />
        <path
          className="prefix__cls-2"
          d="M156.65 113.59l-.58-1.26A81.16 81.16 0 00135.88 85 71.4 71.4 0 0077 68.1a81.09 81.09 0 00-31.67 12.52l-1.16.76a2.3 2.3 0 00-.8 2.79l.63 1.26a81.2 81.2 0 0020.19 27.36 71.36 71.36 0 0058.93 16.88 81.14 81.14 0 0031.62-12.53l1.16-.76a2.22 2.22 0 00.9-1.25 2.2 2.2 0 00-.15-1.54z"
        />
        <path
          d="M155 114.71l-.58-1.21a79.27 79.27 0 00-19.92-26.24 71.72 71.72 0 00-57.69-16.52A79.22 79.22 0 0046 82.45l-1.13.72a2.13 2.13 0 00-.76 2.65l.58 1.18a79.23 79.23 0 0019.93 26.25 71.69 71.69 0 0057.71 16.52 79.08 79.08 0 0030.8-11.72l1.13-.71a2.13 2.13 0 00.76-2.66z"
          fill="#beeda2"
        />
        <motion.path
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.4, repeat: Infinity, repeatDelay: 5,
          }}
          className="prefix__cls-5 eye"
          d="M71.71 81.84L68.62 94"
        />
        <motion.path
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.4, repeat: Infinity, repeatDelay: 5,
          }}
          className="prefix__cls-5 eye"
          d="M117.63 90.61l-3.1 12.16"
        />
        <ellipse
          cx={87.75}
          cy={109.18}
          rx={5.2}
          ry={7.56}
          transform="rotate(-56.98 87.76 109.182)"
          fill="#006838"
        />
        <motion.g
          animate={{
            x: [0, 10, 10, 0],
            y: [0, -10, -10, 0],
          }}
          transition={{
            duration: 8, times: [0, 0.1, 0.9, 1], repeat: Infinity, repeatDelay: 2,
          }}
          id="prefix__hand"
        >
          <path
            id="prefix__HAND_L"
            data-name="HAND L"
            className="prefix__cls-1"
            d="M80.08 131.51a25.56 25.56 0 00-4.62-20.28 25.73 25.73 0 00-7.29 37.35 25.62 25.62 0 0011.91-17.07z"
          />
        </motion.g>
        <motion.path
          animate={{
            y: [0, 60],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 8, repeat: Infinity, repeatDelay: 2,
          }}
          id="prefix__vandr\xE5be drop"
          d="M138.26 84.86a.52.52 0 01.78 0c1.29 1.47 5.35 6.5 5.55 11.88.21 5.64-3.49 6.71-5.94 6.71s-6.21-1.66-5.94-6.71c.29-5.38 4.29-10.41 5.55-11.88z"
          fill="#72cbf1"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
