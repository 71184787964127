import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row } from 'react-bootstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Eboti from '../components/eBotiStates/Eboti6/Eboti6';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (

    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Container className="page-not-found-container">
        <Row className="vh-100 flex-column justify-content-center align-content-center">
          <h1>404: Not Found</h1>
          <p>Uh-oh! Nothing here ...</p>
          <div>
            <Eboti />
          </div>
          <a href="/" className="btn btn-primary">
            Go back Home
          </a>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
